var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{ref:"card",attrs:{"actions":"","block":"","title":_vm.$tc('invoice'),"loading":_vm.loading},on:{"reload":_vm.fetch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('data-table',{attrs:{"data":_vm.invoices,"pages":_vm.pages,"columns":[
        'id',
        {
          key: 'total',
          label: 'value',
          filters: ['currency'],
        },
        {
          key: 'status',
          class: 'badge badge-primary',
          localize: true,
        },
      ]},on:{"change-page":_vm.fetch}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }